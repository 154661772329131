import React from "react"
import Layout from "../components/layouts/Layout2"
import { Hero, Breadcrumb } from "../components/slices"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const ContactPage = ({ data }) => {
  const breadcrumbData = [
    { title: "Home", _meta: "/" },
    { title: "Contact", _meta: "/contact" },
  ]
  return (
    <Layout>
      <Hero data={{ title: "Title" }} className="-compact" />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
      </div>
      <div className="content">
        <div className="[ container -pinched ]">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus
            perspiciatis error, pariatur consequatur cum aliquam illo? Tenetur
            illum praesentium soluta, optio delectus, modi ullam tempora ipsum,
            odio quaerat officiis quas!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
